<div [dir]="direction">
  <nav #navbar class="navbar active sticky">
    <div class="container-fluid">
      <div class="navbar-header">
        <a class="navbar-brand" routerLink="/user-migration/welcome">
          <img src="assets/images/logo.png" alt="" />
        </a>
      </div>
    </div>
  </nav>

  <app-consumer-header></app-consumer-header>
  <router-outlet></router-outlet>

</div>