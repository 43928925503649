import { ConfigService } from '../../config/config.service';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { RightSidebarService, InConfiguration, LanguageService, AuthService } from '@core';
import { environment } from '../../../environments/environment';
import { env } from 'echarts';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit {
  public config!: InConfiguration;
  userImg?: string;
  homePage?: string;
  isNavbarCollapsed = true;
  flagvalue: string | string[] | undefined;
  productName: string | string[] = [];
  productCodeStoreValue?: string;
  selectedProduct: string;
  isOpenSidebar?: boolean;
  docElement: HTMLElement | undefined;
  isFullScreen = false;

  userFullName?: string;
  logoImg = environment.portalDataInfo.headerLogo;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public productCodeuageService: LanguageService
  ) {
    super();
  }
  listProducts = [
    { text: 'Account Management', flag: 'assets/images/flags/us.svg', productCode: 'am', allowClient: false, perm: '' },
    { text: 'Finance Management', flag: 'assets/images/flags/spain.svg', productCode: 'fm', allowClient: false, perm: 'view_finance_management' },
    { text: 'Configuration Management', flag: 'assets/images/flags/spain.svg', productCode: 'ac', allowClient: false, perm: 'view_configuration_management' },
    // { text: 'Case Management', flag: 'assets/images/flags/germany.svg', productCode: 'cm' },
  ];

  ngOnInit() {
    if (environment.clientAccess) this.listProducts = this.listProducts.filter((x) => x.allowClient === true);
    this.config = this.configService.configData;
    this.userFullName =
      this.authService.geotollUserValue.fname +
      ' ' +
      this.authService.geotollUserValue.lname;
    const userRole = this.authService.geotollUserValue.role;
    this.homePage = 'admin/dashboard/main';
    this.productCodeStoreValue = localStorage.getItem('productCode') as string;
    const val = this.listProducts.filter((x) => x.productCode === this.productCodeStoreValue);

    this.listProducts = this.listProducts.filter(
      (x) => (x.perm === '' || x.perm !== '' && this.authService.geotollUserValue.ams_permissions[x.perm] === true)
    );

    this.productName = val.map((element) => element.text);
    this.selectedProduct = (val.length === 0) ? 'Account Management' : this.productName[0];
  }

  callFullscreen() {
    if (!this.isFullScreen) {
      this.docElement?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }
  setProduct(text: string, productCode: string, flag: string) {
    this.productName = text;
    this.flagvalue = flag;
    this.productCodeStoreValue = productCode;
    this.productCodeuageService.setProduct(productCode);

    if (productCode === 'fm') {
      this.router.navigate(['/finance-management/summary-dashboard']);
    }
    if (productCode === 'am') {
      this.router.navigate(['/agency-management/consumers-dashboard']);
    }
    if (productCode === 'ac') {
      this.router.navigate(['/configuration-management/home']);
    }
    if (productCode === 'cm') {
      this.router.navigate(['/case-management/consumers-dashboard']);
    }
    console.log(productCode);
  }
  mobileMenuSidebarOpen(event: Event, className: string) {
    const hasClass = (event.target as HTMLInputElement).classList.contains(
      className
    );
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'false');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'true');
    }
  }
  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
  }
}
