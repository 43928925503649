import { ConfigService } from '../../../config/config.service';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, ElementRef, OnInit, Renderer2, } from '@angular/core';
import { Router } from '@angular/router';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { RightSidebarService, InConfiguration, ConsumerAuthService, ConsumerPortalConfigurationService } from '@core';

@Component({
  selector: 'app-consumer-header',
  templateUrl: './consumer.header.component.html',
  styleUrls: ['./consumer.header.component.scss'],
})
export class ConsumerHeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit {
  public config!: InConfiguration;
  homePage?: string;
  isNavbarCollapsed = true;
  isOpenSidebar?: boolean;
  docElement: HTMLElement | undefined;
  userFullName?: string;
  isConsumerLoggedIn = false;
  envConfig: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private consumerAuthService: ConsumerAuthService,
    private consumerPortalConfigurationService: ConsumerPortalConfigurationService,
    private router: Router
  ) {
    super();
    this.isConsumerLoggedIn = !!this.consumerAuthService.geotollConsumerValue?.id;
    this.envConfig = this.consumerPortalConfigurationService.getEnvironmentConfig();
  }

  ngOnInit() {
    this.initResponsiveness();
    this.config = this.configService.configData;
    this.userFullName =
      this.consumerAuthService.geotollConsumerValue.fname +
      ' ' +
      this.consumerAuthService.geotollConsumerValue.lname;
    this.homePage = '';
  }

  mobileMenuSidebarOpen(event: Event, className: string) {
    const hasClass = (event.target as HTMLInputElement).classList.contains(
      className
    );
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }

  initResponsiveness(): void {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
      this.isNavbarCollapsed = false;
    } else {
      this.isNavbarCollapsed = true;
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }

  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    console.log("BBBBBBB");
    if (hasClass) {
      console.log("11111111111"); //isNavbarCollapsed
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'false');
    } else {
      console.log("2222222"); //isNavbarCollapsed
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'true');
    }
  }
  logout() {
    this.subs.sink = this.consumerAuthService.logout().subscribe((res) => {
      this.isConsumerLoggedIn = false;
      if (!res.success) {
        this.router.navigate([this.envConfig.portalDataInfo.logoUrl]);
      }
    });
  }
}
