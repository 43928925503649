/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { BaseSidebarComponent } from '../base-sidebar.component';
import { CONSUMER_ROUTES } from './consumer-myaccount-sidebar';
import { environment } from '../../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-consumer-myaccount-sidebar',
  templateUrl: './consumer-myaccount-sidebar.component.html',
  styleUrls: ['./consumer-myaccount-sidebar.component.scss'],
})
export class ConsumerMyaccountSidebarComponent extends BaseSidebarComponent implements OnInit {
  error: any = null;

  ngOnInit() {
    this.userFullName =
      this.consumerAuthService.geotollConsumerValue.fname +
      ' ' +
      this.consumerAuthService.geotollConsumerValue.lname;
    this.getAgencyPortalConfiguration();

    // this.sidebarItems = CONSUMER_ROUTES.filter(
    //   (x) => !x.envPermissionVar || (x.envPermissionVar && environment[x.envPermissionVar as keyof typeof environment] === true)
    // );

    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }

  getAgencyPortalConfiguration() {
    this.accountManagementService
      .getAgencyConsumerPortalConfiguration()
      .subscribe({
        next: (response) => {
          this.sidebarItems = CONSUMER_ROUTES.filter((x) => this.checkMenuEnabled(x.backendPermissionVar!, response.data.configurations));
        },
        error: (error: HttpErrorResponse) => {
          this.error = error;
        },
      });
  }


  checkMenuEnabled(permVar: string, permObj: any): boolean {
    if (permVar === '') return true;
    const navPerm = permObj[permVar] && permObj[permVar].config_input_value === true ? true : false;
    return navPerm;
  }
}
