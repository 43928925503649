import { RouteInfo } from '../sidebar.metadata';

export const FM_ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Finance Dashboards',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
  {
    path: '/finance-management/summary-dashboard',
    title: 'Summary Dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'people_alt',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
  {
    path: '',
    title: 'Summary Reports',
    iconType: 'material-icons-two-tone',
    icon: 'hourglass_full',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [
      {
        path: '/finance-management/summary_report/mro_type',
        title: 'By MRO Type',
        iconType: 'material-icons-two-tone',
        icon: 'sort',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/finance-management/summary_report/transactions_type',
        title: 'By Transaction Type',
        iconType: 'material-icons-two-tone',
        icon: 'sort',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/finance-management/summary_report/dc_mileage',
        title: 'Data Collection Mileage',
        iconType: 'material-icons-two-tone',
        icon: 'sort',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/finance-management/summary_report/adjustments',
        title: 'Mileage Adjustments',
        iconType: 'material-icons-two-tone',
        icon: 'sort',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/finance-management/summary_report/mileage',
        title: 'RUC Mileage',
        iconType: 'material-icons-two-tone',
        icon: 'sort',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      }
    ],
  },
  {
    path: '/finance-management/journal',
    title: 'Journal',
    iconType: 'material-icons-two-tone',
    icon: 'track_changes',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
  {
    path: '/finance-management/recievables',
    title: 'Recievables',
    iconType: 'material-icons-two-tone',
    icon: 'attach_money',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
  {
    path: '/finance-management/adjustments',
    title: 'Adjustments',
    iconType: 'material-icons-two-tone',
    icon: 'money_off',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
  {
    path: '/finance-management/consumers-dashboard',
    title: 'Payables',
    iconType: 'material-icons-two-tone',
    icon: 'attach_money',
    class: '',
    groupTitle: false,
    badge: 'x',
    badgeClass: 'badge bg-orange sidebar-badge float-end',
    role: ['admin', 'agency'],
    submenu: [],
  },
  // {
  //   path: '/finance-management/consumers-dashboard',
  //   title: 'General Ledger',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'visibility',
  //   class: '',
  //   groupTitle: false,
  //   badge: 'x',
  //   badgeClass: 'badge bg-orange sidebar-badge float-end',
  //   role: ['admin', 'agency'],
  //   submenu: [],
  // },
  {
    path: '/finance-management/consumers-dashboard',
    title: 'Accounting Audit',
    iconType: 'material-icons-two-tone',
    icon: 'security',
    class: '',
    groupTitle: false,
    badge: 'x',
    badgeClass: 'badge bg-orange sidebar-badge float-end',
    role: ['admin', 'agency'],
    submenu: [],
  },
  // {
  //   path: '',
  //   title: 'Agency Configurations',
  //   iconType: '',
  //   icon: '',
  //   class: '',
  //   groupTitle: true,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['admin', 'agency'],
  //   submenu: [],
  // },
  // {
  //   path: '/finance-management/ruc-config',
  //   title: 'RUC System Configurations',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'settings',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['admin', 'agency'],
  //   submenu: [],
  // },
  // {
  //   path: '/finance-management/ruc-charges-config',
  //   title: 'RUC Charges Configurations',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'attach_money',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['admin', 'agency'],
  //   submenu: [],
  // },
];
