import { Role } from './role';

export class User {
  id!: number;
  img!: string;
  username!: string;
  password!: string;
  firstName!: string;
  lastName!: string;
  role!: Role;
  token!: string;
}

export class GeoTollUser {
  id!: number;
  agency_id!: number;
  agency_user_roles_id !: number;
  created_at !: string;
  email!: string;
  fname!: string;
  lname!: string;
  role: string;
  permissions !: any;
  ams_permissions !: any;
  status !: string;
  updated_at !: string;
  token!: string;
}

export class GeoTollAuth {
  agency!: any;
  config!: any;
  user!: any;
  token!: string;
  ref_token!: string;
}

export class GeoTollConsumer {
  id!: number;
  agency_id!: number;
  agency_user_id!: string;
  created_at !: string;
  email!: string;
  fname!: string;
  lname!: string;
  phone!: string;
  status !: string;
  updated_at !: string;
  token!: string;
  verification_settings: any;
  ams_permissions !: any;
  acct_closed_on !: string
}

export interface ChangePasswordRequest {
  new_password1: string;
  new_password2: string;
  old_password?: string;
}

export interface UpdateProfileRequest {
  communication_pref?: string;
}

export class ConsumerLoginRequest {
  email!: string;
  password!: string;
  agency_id!: number;
}
