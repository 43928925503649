<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink={{homePage}}>
        <img src="assets/images/logos/{{logoImg}}" alt="" />
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngClass]="isNavbarCollapsed ? '' : 'show'">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-button (click)="callSidemenuCollapse()" class="sidemenu-collapse nav-notification-icons"> <i
              class="material-icons-outlined icon-color">
              menu
            </i></button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li class="nav-item user_profile" *ngIf="listProducts.length > 1">
          <button mat-button [matMenuTriggerFor]="productmenu">
            <div class="badge col-green">{{selectedProduct}}</div>
          </button>
          <mat-menu #productmenu="matMenu">
            <div *ngFor="let item of listProducts" class="product-item">
              <button mat-menu-item class="dropdown-item product-item-list"
                (click)="setProduct(item.text, item.productCode, item.flag)"
                [ngClass]="{'active': productCodeStoreValue === item.productCode}">
                <span class="align-middle">{{item.text}}</span>
              </button>
            </div>
          </mat-menu>
        </li>

        <li class="nav-item user_profile">
          <button mat-button [matMenuTriggerFor]="profilemenu">
            <!-- <i
              class="material-icons-outlined icon-color">
              user
            </i> -->
            <span>{{userFullName}}</span>
            <!-- <div class="chip dropdown-toggle" ngbDropdownToggle class="pt-0">
              <img src="{{userImg}}" class="user_img" width="32" height="32" alt="User">
              <span>{{userFullName}}</span>
            </div> -->
          </button>
          <mat-menu #profilemenu="matMenu" class="profile-menu">
            <div class="noti-list">
              <div class="menu ">
                <div class="user_dw_menu">
                  <button mat-menu-item>
                    <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>Account
                  </button>
                  <!-- <button mat-menu-item>
                    <app-feather-icons [icon]="'mail'" [class]="'user-menu-icons'"></app-feather-icons>Inbox
                  </button>
                  <button mat-menu-item>
                    <app-feather-icons [icon]="'settings'" [class]="'user-menu-icons'"></app-feather-icons>Settings
                  </button> -->
                  <button mat-menu-item (click)="logout()">
                    <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Logout
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>