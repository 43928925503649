/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { BaseSidebarComponent } from '../base-sidebar.component';
import { CM_ROUTES } from './configuration-management-sidebar';

@Component({
  selector: 'app-configuration-management-sidebar',
  templateUrl: './configuration-management-sidebar.component.html',
  styleUrls: ['./configuration-management-sidebar.component.scss'],
})
export class ConfigurationManagementSidebarComponent extends BaseSidebarComponent implements OnInit {

  ngOnInit() {
    if (this.authService.geotollUserValue && !this.isConsumerPortal) {
      const userRole = this.authService.geotollUserValue.role;
      this.userFullName =
        this.authService.geotollUserValue.fname +
        ' ' +
        this.authService.geotollUserValue.lname;

      this.sidebarItems = CM_ROUTES.filter(
        (x) => x.role.indexOf(userRole) !== -1 || x.role.indexOf('All') !== -1
      );
    }

    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }

}
