import { NgModule } from '@angular/core';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { ConsumerHeaderComponent } from './layout/header/consumer-header/consumer.header.component';
import { ConsumerMyaccountHeaderComponent } from './layout/header/consumer-myaccount-header/consumer.myaccount.header.component';
import { PageLoaderComponent } from './layout/page-loader/page-loader.component';
import { RightSidebarComponent } from './layout/right-sidebar/right-sidebar.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { ConsumerLayoutComponent } from './layout/app-layout/consumer-layout/consumer-layout.component';
import { fakeBackendProvider } from './core/interceptor/fake-backend';
import { ErrorInterceptor } from './core/interceptor/error.interceptor';
import { JwtInterceptor } from './core/interceptor/jwt.interceptor';
import { ConsumerJwtInterceptor } from './core/interceptor/consumer.jwt.interceptor';
// import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { APP_CONFIG, APP_DI_CONFIG } from "./config/app-config.constants";
import { FinanceManagementLayoutComponent } from './layout/app-layout/finance-management/finance-management-layout.component';
import { FinanceManagementSidebarComponent } from './layout/sidebar/finance-management/finance-management-sidebar.component';
import { ConsumerMyaccountSidebarComponent } from './layout/sidebar/consumer-myaccount/consumer-myaccount-sidebar.component';
import { ConsumerMyaccountLayoutComponent } from './layout/app-layout/consumer-myaccount/consumer-myaccount-layout.component';
import { AccountManagementSidebarComponent } from './layout/sidebar/account-management/account-management-sidebar.component';
import { AccountManagementLayoutComponent } from './layout/app-layout/account-management/account-management-layout.component';
import { ConsumerVerifyMigrationLayoutComponent } from './layout/app-layout/consumer-myaccount/consumer-verify-migration-layout.component';
import { ConfigurationManagementSidebarComponent } from './layout/sidebar/configuration-management/configuration-management-sidebar.component';
import { ConfigurationManagementLayoutComponent } from './layout/app-layout/configuration-management/configuration-management-layout.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    RightSidebarComponent,
    AuthLayoutComponent,
    ConsumerLayoutComponent,
    ConsumerHeaderComponent,
    ConsumerMyaccountHeaderComponent,
    FinanceManagementLayoutComponent,
    FinanceManagementSidebarComponent,
    ConsumerMyaccountSidebarComponent,
    AccountManagementSidebarComponent,
    AccountManagementLayoutComponent,
    ConsumerMyaccountLayoutComponent,
    ConsumerVerifyMigrationLayoutComponent,
    ConfigurationManagementSidebarComponent,
    ConfigurationManagementLayoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgScrollbarModule,
    LoadingBarRouterModule,
    // core & shared
    CoreModule,
    SharedModule,
  ],
  providers: [
    { provide: APP_CONFIG, useValue: APP_DI_CONFIG },
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ConsumerJwtInterceptor, multi: true },
    fakeBackendProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
