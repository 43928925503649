import { Injectable } from '@angular/core';
import { InConfiguration } from '../core/models/config.interface';
import { environment } from "../../environments/environment";

const DARK_THEME = {
  layout: {
    rtl: false, // options:  true & false
    variant: 'dark', // options:  light & dark
    theme_color: 'black', // options:  white, black, purple, blue, cyan, green, orange
    logo_bg_color: 'black', // options:  white, black, purple, blue, cyan, green, orange
    sidebar: {
      collapsed: false, // options:  true & false
      backgroundColor: 'dark', // options:  light & dark
    },
  },
};

const WHITE_THEME = {
  layout: {
    rtl: false, // options:  true & false
    variant: 'light', // options:  light & dark
    theme_color: 'white', // options:  white, black, purple, blue, cyan, green, orange
    logo_bg_color: 'white', // options:  white, black, purple, blue, cyan, green, orange
    sidebar: {
      collapsed: false, // options:  true & false
      backgroundColor: 'light', // options:  light & dark
    },
  },
};

const AMS_THEME = {
  layout: {
    rtl: false, // options:  true & false
    variant: 'light', // options:  light & dark
    theme_color: 'black', // options:  white, black, purple, blue, cyan, green, orange
    logo_bg_color: 'black', // options:  white, black, purple, blue, cyan, green, orange
    sidebar: {
      collapsed: false, // options:  true & false
      backgroundColor: 'dark', // options:  light & dark
    },
  },
};

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public configData!: InConfiguration;
  public configConsumerData!: InConfiguration;

  constructor() {
    this.setConfigData();
    this.setConsumerConfigData();
  }

  setConfigData() {
    this.configData = this.getThemeFromEnvironment();
  }

  setConsumerConfigData() {
    this.configConsumerData = this.getThemeFromEnvironment();
  }

  getThemeFromEnvironment() {
    switch (environment.portalTheme) {
      case "light":
        return WHITE_THEME;

      case "dark":
        return DARK_THEME;

      case "ams":
        return AMS_THEME;

      default:
        return WHITE_THEME;
    }
  }

}
