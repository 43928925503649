<nav #navbar class="navbar active sticky {{envConfig.portalDataInfo.agencyClassOverride}}">
  <div class="container-fluid">
    <div class="navbar-header {{envConfig.portalDataInfo.agencyClassOverride}}">
      <a class="navbar-brand {{envConfig.portalDataInfo.agencyClassOverride}}" routerLink={{envConfig.portalDataInfo.logoUrl}}>
        <img src="assets/images/logos/{{envConfig.portalDataInfo.headerLogo}}" alt="" />
      </a>
    </div>

    <div class="collapse navbar-collapse" [ngClass]="isNavbarCollapsed ? '' : 'show'">
      <ul class="nav navbar-nav navbar-right">
        <li class="fullscreen" *ngIf="envConfig.portalLinks.showHome">
          <button routerLink="/home" mat-button class="nav-notification-icons icon-color">Home</button>
        </li>
        <li class="nav-item">
          <button routerLink="/home/faqs" mat-button class="icon-color">FAQs</button>
        </li>
        <li class="nav-item" *ngIf="envConfig.portalLinks.showContactUs">
          <button routerLink="/home/contact-us" mat-button class="icon-color">Contact Us</button>
        </li>
        <li class="nav-item" *ngIf="envConfig.portalLinks.showSignUp">
          <button routerLink="/home/signup" mat-button class="icon-color">Sign Up</button>
        </li>
        <li class="nav-item">
          <button routerLink="/home/signin" mat-button class="icon-color">Sign In</button>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>