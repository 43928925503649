import { Component } from '@angular/core';
import { BaseLayoutComponent } from '../base-layout.component';

@Component({
  selector: 'app-consumer-verify-migration-layout',
  templateUrl: './consumer-verify-migration-layout.component.html',
  styleUrls: [],
})

export class ConsumerVerifyMigrationLayoutComponent extends BaseLayoutComponent {

}
