import { Component } from '@angular/core';
import { BaseLayoutComponent } from '../base-layout.component';

@Component({
  selector: 'app-account-management-layout',
  templateUrl: './account-management-layout.component.html',
  styleUrls: [],
})

export class AccountManagementLayoutComponent extends BaseLayoutComponent {
  
}
