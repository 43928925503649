import { RouteInfo } from '../sidebar.metadata';

export const CM_ROUTES: RouteInfo[] = [
  {
    path: '/configuration-management/home',
    title: 'Agency Configurations',
    iconType: 'material-icons-two-tone',
    icon: 'settings',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
  {
    path: '',
    title: 'Global Configurations',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
  {
    path: '/configuration-management/global-config',
    title: 'System Configurations',
    iconType: 'material-icons-two-tone',
    icon: 'settings',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
  {
    path: '',
    title: 'RUC Configurations',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
  {
    path: '/configuration-management/ruc-config',
    title: 'RUC System Configurations',
    iconType: 'material-icons-two-tone',
    icon: 'settings',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
  {
    path: '/configuration-management/ruc-charges-config',
    title: 'RUC Charges Configurations',
    iconType: 'material-icons-two-tone',
    icon: 'attach_money',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
  {
    path: '',
    title: 'eTolling Configurations',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
  {
    path: '/configuration-management/etoll-config',
    title: 'eToll System Configurations',
    iconType: 'material-icons-two-tone',
    icon: 'settings',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
  {
    path: '/configuration-management/tollplazas',
    title: 'Agency Plazas',
    iconType: 'material-icons-two-tone',
    icon: 'location_city',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
  {
    path: '/configuration-management/toll-transactions',
    title: 'Agency Transactions',
    iconType: 'material-icons-two-tone',
    icon: 'attach_money',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
  },
];
