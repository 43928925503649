export const environment = {
  production: true,
  apiUrl: 'https://api.geotoll.com',
  version: "C20240202.01",
  defaultTimezone: "America/New_York",
  consumerPortal: false,
  skipConsumerConsent: true,
  clientAccess: false,  
  portalTheme: "ams",
  mroOptions: {
    showSimpleApp: true,
    showSmartApp: true,
    showOBDDongle: true,
    showTelematics: true,
  },
  vehicle: {
    showAddVehicle: true,
    showDeleteVehicle: true,
  },
  portalDataInfo: {
    agencyId: 11,
    agencyName: "GeoToll",
    headerLogo: "11_header.png",
    footerLogo: "11_footer.svg",
    agencyClassOverride: "",
    rucProductTitle: "Road Usage Charge",
    rucConsumerAgreementProductTitle:"Road Usage Charge (RUC) Pilot",
    rucProductAbbr: "RUC",
    supportEmail: "support@geotoll.com",
    supportPhone: "1-844-391-TOLL",
    logoUrl: "/agency-management/consumers-dashboard",
    loginPageTitle:  "Conduent Account Management"
  },
  portalLinks: {
    showHome: true,
    showContactUs: true,
    showSignUp: true,
    showRUCProduct: true,
    showCongestionPricingProduct: true,
    showETollingProduct: true,
    showCustomerSupport: true,
    showSupportChatOption: true
  }
};