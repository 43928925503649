import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../service/auth.service';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class ConsumerPortalGuard {
  isConsumerPortal = environment.consumerPortal ? true : false;
  constructor(private authService: AuthService, private router: Router) { }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isConsumerPortal) {
      return true;
    }

    this.router.navigate(['/authentication/signin']);
    return false;
  }
}
