import { InjectionToken } from "@angular/core";
import { IAppConfig } from "./app-config.interface";

export const APP_DI_CONFIG: IAppConfig = {
    api_user_login: "login",
    api_account_management_search_accounts: "/account-management/agency/user-search",
    api_account_management_consumer_info: "/account-management/user/info",
    api_account_management_consumer_vehicles: "/account-management/user/vehicles",
    api_account_management_consumer_vehicle_odometer_history: "/account-management/user/vehicle-odometer-history",
    api_account_management_consumer_contacts: "/account-management/user/contacts",
    api_account_management_consumer_contact_addresses: "/account-management/user/addresses",
    api_account_management_consumer_contact_add: "/account-management/user/contact",
    api_account_management_consumer_contact_address_add: "/account-management/user/address",
    api_agency_login: "/auth/aglogin",
    api_vin_decode: "/lookup/vin-decode/",
    api_vin_decode_guest: "/lookup/vin-decode-guest/",
    api_preregister_new_user: "/account-management/user/pre-register",
    api_preregistered_users_list: "/account-management/user/pre-register/list",
    api_approve_preregistered_user: "/account-management/user/pre-register/approve",
    api_reject_preregistered_user: "/account-management/user/pre-register/reject",
    api_preregister_new_user_self: "/register/pre-register",
    api_verify_register_invite: "/register/invite",
    api_consumer_set_password: "/user/password",
    api_consumer_change_password: "/user/changepwd",
    api_consumer_forgot_password: "/auth/forgot-password",
    api_consumer_update_profile: "/user/profile",
    api_consumer_login: "/auth/login",
    api_consumer_vehicle_trip_history: "/ruc/vehicle-trip-history",
    api_account_management_consumer_vehicle_trip_history: "/account-management/user/vehicle-trip-history",
    api_account_management_consumer_correspondences: "/account-management/user/correspondences",
    api_account_management_consumer_notes: "/account-management/user/notes",
    api_account_management_delete_consumer_record: "/account-management/user/delete-record",
    api_account_management_consumer_devices: "/account-management/user/devices",
    api_account_management_consumer_login_history: "/account-management/user/login-history",
    api_account_management_agency_users_account_status: "/account-management/agency/users-account-status",
    api_account_management_consumer_transactions: "/transaction-processor/transactions",
    api_account_management_agency_users_vehicles_stats: "/account-management/agency/users-vehicles-stats",
    api_account_management_agency_users_payment_profiles: "/account-management/user/payment-profiles",
    api_account_management_agency_user_add_payment_profile: "/account-management/user/add-payment-profile",
    api_account_management_consumer_audit_history: "/user/audithistory",
    api_accounts_pending_admin_approval: "/account-management/user/register/pending",
    api_accounts_new_admin_approval_transmit: "/account-management/user/register/transmit",
    api_approve_pending_user_vehicle: "/account-management/user/register/approve",
    api_reject_pending_user_vehicle: "/account-management/user/register/reject",
    api_register_new_user: "/account-management/user/register",
    api_account_management_agency_odometer_data: "/account-management/agency/odometer-data",
    api_odometer_record_detail: "/account-management/agency/odometer-record-data",
    api_update_odometer_record: "/account-management/agency/odometer-record",
    api_pending_vin_approvals_list: "/account-management/agency/pending-vin-approvals",
    api_vin_record_detail: "/account-management/agency/vin-record-data",
    api_update_vin_record: "/account-management/agency/vin-record",
    api_cs_consumer_generate_otp: "/account-management/user/cs-generate-otp",
    api_cs_consumer_verify_otp: "/account-management/user/cs-verify-otp",
    api_consumer_vehicle_update_mro_type: "/account-management/user/vehicle/mro_type",
    api_account_management_consumer_statements_list: "/account-management/user/get-statements",
    api_account_management_consumer_statement_details: "/account-management/user/get-statement",

    // Case Management APIs
    api_customer_support_create_ticket: "/customer-support/ticket/create",
    api_customer_support_view_tickets: "/customer-support/ticket/list",
    api_customer_support_view_ticket_comments: "/customer-support/ticket/comments",
    api_account_management_add_consumer_vehicle: "/account-management/user/vehicle",
    api_customer_support_update_ticket: "/customer-support/ticket/update",

    // Finance Management APIs
    api_finance_management_summary_report: "/finance-management/agency/revenue-report",
    api_finance_management_agency_journal: "/finance-management/agency/journal",
    api_finance_management_agency_ruc_configuration: "/finance-management/agency/ruc-config",
    api_finance_management_agency_etolling_configuration: "/finance-management/agency/etoll-config",
    api_finance_management_agency_ruc_charges_configuration: "/finance-management/agency/ruc-rate-config",
    api_finance_management_summary_report_by_mro_type: "/finance-management/agency/summary-report-by-mrotype",
    api_finance_management_summary_report_by_transaction_type: "/finance-management/agency/summary-report-by-transactiontype",
    api_finance_management_summary_report_mileage_adjustment: "/finance-management/agency/summary-report-adjustment",
    api_finance_management_summary_report_net_ruc_mileage: "/finance-management/agency/summary-report",
    api_finance_management_summary_report_datacollection_mileage: "/finance-management/agency/summary-report-mileage",
    api_finance_management_agency_recievables: "/finance-management/agency/receivables",
    api_finance_management_agency_adjustments: "/finance-management/agency/adjustments",
    api_finance_management_agency_payables: "/finance-management/agency/payables",

    // Miles Adjustment
    api_agency_get_rules_data: "/account-management/agency/get-rules-data",
    api_consumer_vehicle_add_miles_adjustment: "/account-management/agency/add-adjustment-data",

    //
    api_account_management_consumer_profile_dashboard: "/account-management/user/dashboard",
    api_account_management_vehicle_start_telematics_enrollment: "/data-collection/telematics/smartcar/enroll?vehicle_id=",
    api_account_management_vehicle_complete_telematics_enrollment: "/data-collection/telematics/smartcar/enroll",

    api_account_management_consumer_vehicle_mro_types_eligibility: "/account-management/user/vehicle/mro-eligibility",
    api_complete_user_registration_migration: "/account-management/user/complete-migration",

    api_upload_customers_migration_data: "/account-management/agency/import-accounts",
    api_upload_customers_ruc_messages_data: "/account-management/agency/import-vin-summary",
    api_agency_registration_config: "/lookup/registration-config/",
    api_agency_consumer_portal_config: "/lookup/consumer-portal-config",
    api_configuration_management_agency_global_configuration: "/finance-management/agency/global-config",

    // eTolling Tollplazas APIs
    api_agency_tollplazas_list: "/agency/tollplazas",
    api_agency_tollplaza_transactions_list: "/agency/transactions",
    api_consumer_tollplaza_transactions_list: "/transaction-processor/etoll-transactions",

    test_data: [
        { id: "post_date", label: "Post \n Date", width: "", type: "" },
        { id: "detail", label: "Description", width: "", type: "" },
        { id: "agency_name", label: "Agency \n Name", width: "", type: "" },
        { id: "detail", label: "Description", width: "", type: "" },
    ],
    usa_states: [
        { code: "AL", label: "Alabama" },
        { code: "AK", label: "Alaska" },
        { code: "AZ", label: "Arizona" },
        { code: "AR", label: "Arkansas" },
        { code: "CA", label: "California" },
        { code: "CO", label: "Colorado" },
        { code: "CT", label: "Connecticut" },
        { code: "DE", label: "Delaware" },
        { code: "DC", label: "District Of Columbia" },
        { code: "FL", label: "Florida" },
        { code: "GA", label: "Georgia" },
        { code: "HI", label: "Hawaii" },
        { code: "ID", label: "Idaho" },
        { code: "IL", label: "Illinois" },
        { code: "IN", label: "Indiana" },
        { code: "IA", label: "Iowa" },
        { code: "KS", label: "Kansas" },
        { code: "KY", label: "Kentucky" },
        { code: "LA", label: "Louisiana" },
        { code: "ME", label: "Maine" },
        { code: "MD", label: "Maryland" },
        { code: "MA", label: "Massachusetts" },
        { code: "MI", label: "Michigan" },
        { code: "MN", label: "Minnesota" },
        { code: "MS", label: "Mississippi" },
        { code: "MO", label: "Missouri" },
        { code: "MT", label: "Montana" },
        { code: "NE", label: "Nebraska" },
        { code: "NV", label: "Nevada" },
        { code: "NH", label: "New Hampshire" },
        { code: "NJ", label: "New Jersey" },
        { code: "NM", label: "New Mexico" },
        { code: "NY", label: "New York" },
        { code: "NC", label: "North Carolina" },
        { code: "ND", label: "North Dakota" },
        { code: "OH", label: "Ohio" },
        { code: "OK", label: "Oklahoma" },
        { code: "OR", label: "Oregon" },
        { code: "PA", label: "Pennsylvania" },
        { code: "RI", label: "Rhode Island" },
        { code: "SC", label: "South Carolina" },
        { code: "SD", label: "South Dakota" },
        { code: "TN", label: "Tennessee" },
        { code: "TX", label: "Texas" },
        { code: "UT", label: "Utah" },
        { code: "VT", label: "Vermont" },
        { code: "VA", label: "Virginia" },
        { code: "WA", label: "Washington" },
        { code: "WV", label: "West Virginia" },
        { code: "WI", label: "Wisconsin" },
        { code: "WY", label: "Wyoming" },
    ],
    countries: [
        // { code: "CAN", label: "Canada" },
        { code: "USA", label: "United States" },
    ],
    months: [
        { val: 1, label: "January" },
        { val: 2, label: "February" },
        { val: 3, label: "March" },
        { val: 4, label: "April" },
        { val: 5, label: "May" },
        { val: 6, label: "June" },
        { val: 7, label: "July" },
        { val: 8, label: "August" },
        { val: 9, label: "September" },
        { val: 10, label: "October" },
        { val: 11, label: "November" },
        { val: 12, label: "December" },
    ],
    card_years: [
        { val: 2023, label: "2023" },
        { val: 2024, label: "2024" },
        { val: 2025, label: "2025" },
        { val: 2026, label: "2026" },
        { val: 2027, label: "2027" },
        { val: 2028, label: "2028" },
        { val: 2029, label: "2029" },
        { val: 2030, label: "2030" },
    ],
    preferred_communication_modes: [
        { val: "email", label: "Email" },
        { val: "text", label: "Text/SMS" },
    ],
    inactivate_vehicle_reason_codes: [
        { val: "Vehicle sold", label: "Vehicle sold" },
        { val: "Owner moved out of state", label: "Owner moved out of state" },
        { val: "Owner wished to leave program", label: "Owner wished to leave program" },
        { val: "Vehicle totaled", label: "Vehicle totaled" },
        { val: "Vehicle missing or stolen", label: "Vehicle missing or stolen" }
    ],
    inactivate_contact_reason_codes: [
        { val: "Do not want this person on my account", label: "Do not want this person on my account" },
        { val: "Other", label: "Other" },
    ],
    inactivate_contact_address_reason_codes: [
        { val: "Do not use this address anymore", label: "Do not use this address anymore" },
        { val: "Other", label: "Other" },
    ],
    inactivate_payment_method_codes: [
        { val: "Card Expired", label: "Card Expired" },
        { val: "Do not own this card anymore", label: "Do not own this card anymore" },
        { val: "Other", label: "Other" },
    ],
    inactivate_user_account_codes: [
        { val: "Consumer no longer participating in the program", label: "Consumer no longer participating in the program" },
        { val: "Account closed due to no payment", label: "Account closed due to no payment" },
        { val: "Other", label: "Other" },
    ],
    consumer_customer_support_call_reasons: [
        { val: "General program information", label: "General program information" },
        { val: "Participant account information and billing questions", label: "Participant account information and billing questions" },
        { val: "MRO issue diagnostic and troubleshooting information", label: "MRO issue diagnostic and troubleshooting information" },
        { val: "Technical support(website, applications, etc.)", label: "Technical support(website, applications, etc.)" },
        { val: "Answers to Frequently Asked Questions", label: "Answers to Frequently Asked Questions" },
        { val: "Other", label: "Other" },
    ],
    vehicle_update_mro_type_reason_codes: [
        { val: "Vehicle Issues", label: "Vehicle Issues" },
        { val: "Consumer wants to switch to different MRO Type", label: "Consumer wants to switch to different MRO Type" },
        { val: "Other", label: "Other" },
    ],
    allowed_mro_types_options: [
        {
            type: 'smartapp',
            label: 'Smart Mobile App (GPS based)',
            desc: 'Smart App using GPS',
            enabled: false,
            color: 'primary',
            active: false,
            icon: "smartphone",
            default: false,
            eligible: false,
        },
        {
            type: 'simpleapp',
            label: 'Manual Odometer upload',
            desc: 'Manual Odometer upload',
            enabled: true,
            color: 'primary',
            active: false,
            icon: "keyboard",
            default: false,
            eligible: false,
        },
        {
            type: 'telematics',
            label: 'Telematics',
            desc: 'Telematics',
            enabled: false,
            color: 'primary',
            active: false,
            icon: "device_hub",
            default: false,
            eligible: false,
        },
        {
            type: 'obd',
            label: 'OBD Adapter',
            desc: 'OBD Adapter',
            enabled: false,
            color: 'primary',
            active: false,
            icon: "share_location",
            default: false,
            eligible: false,
        },
    ],

    transaction_type_options: [
        {
            type: 'adj_message',
            label: 'Fuel Tax Credit',
            desc: 'Adjustments - Fuel Tax and account adjustments',
            icon: "money_off",
            color: "red",
            icon_color: "red",
            val: 0
        },
        {
            type: 'mileage_message',
            label: 'Mileage charges',
            desc: 'RUC Mileage charges',
            icon: "attach_money",
            color: "teal",
            icon_color: "",
            val: 0
        },
        {
            type: 'payment',
            label: 'Account Payments',
            desc: 'Account Payments',
            icon: "payment",
            color: "teal",
            icon_color: "",
            val: 0
        },
        {
            type: 'late_fee',
            label: 'Late Fee',
            desc: 'Late Fee',
            icon: "watch_later",
            color: "teal",
            icon_color: "",
            val: 0
        },
    ],

    customer_support_issue_types_info: [
        {
            value: 'issue_account',
            label: 'Account Issues',
            subcategories: [
                // {
                //     value: 'subtype_new',
                //     label: 'New Account',
                // },
                {
                    value: 'subtype_password',
                    label: 'Account Password',
                },
                {
                    value: 'subtype_sharing',
                    label: 'Account Sharing',
                },
                {
                    value: 'subtype_vehicles',
                    label: 'Multiple Vehicles',
                },
            ],
            questions: [
                {
                    value: 'Can I share my account with a family member?',
                    desc: 'Yes you would be able to invite family members through the mobile application. The invitee would complete the registration and would have access to your vehicles.',
                    sub_issue: ['subtype_sharing']
                },
            ]
        },
        {
            value: 'issue_billing_transaction',
            label: 'Billing / Transaction Issues',
            subcategories: [
                {
                    value: 'subtype_invoice',
                    label: 'Invoice',
                },
                {
                    value: 'subtype_statement',
                    label: 'Statement',
                },
                {
                    value: 'subtype_refund',
                    label: 'Refund',
                },
                {
                    value: 'subtype_other_billing_issue',
                    label: 'Other Billing Question',
                },
                {
                    value: 'subtype_dispute',
                    label: 'Dispute a Transaction',
                },
                {
                    value: 'subtype_other_issue_with_a_transaction',
                    label: 'Other Transaction Question',
                }
            ],
            questions: [
                {
                    value: 'I do not understand my invoice or statement?',
                    desc: 'Your invoice has all the information properly structured. It starts with Summary of your RUC mileage and the RUC charges. ',
                    sub_issue: ['subtype_invoice', 'subtype_statement']
                },
                {
                    value: 'I think I have been over charged?',
                    desc: 'Please review the details section to understand your daily activity. If you still have question, please send us your question.',
                    sub_issue: ['subtype_invoice', 'subtype_refund']
                },
                {
                    value: 'My Refund do not show up on my account?',
                    desc: 'Refund generally takes 30 days to process and show up on online account. If you think it has been more than 30 days since it was processed, please send us the details or please wait.',
                    sub_issue: ['subtype_refund']
                },
                {
                    value: 'When and how will I receive an invoice?',
                    desc: 'Invoices are issued on a quarterly basis. Your invoice will be available on your account. You will also receive your invoice by e-mail. You will have 30 days to pay it online.',
                    sub_issue: ['subtype_invoice']
                },
                {
                    value: 'How do I pay my invoice?',
                    desc: 'You can pay your invoice online with a Credit or Debit Card (VISA/MASTERCARD/DISCOVER)',
                    sub_issue: ['subtype_invoice']
                },
            ]
        },
        {
            value: 'issue_technical',
            label: 'Technical Issues',
            subcategories: [
                {
                    value: 'subtype_bluetooth',
                    label: 'Vehicle Bluetooth',
                },
                {
                    value: 'subtype_vin',
                    label: 'Vehicle VIN',
                },
                {
                    value: 'subtype_odometer',
                    label: 'Vehicle Odometer',
                },
                {
                    value: 'subtype_android_permissions',
                    label: 'Android Application Permissions',
                },
                {
                    value: 'subtype_other_issue_with_android',
                    label: 'Other Issue with Android Device',
                },
                {
                    value: 'subtype_ios_permissions',
                    label: 'iOS Application Permissions',
                },
                {
                    value: 'subtype_other_issue_with_ios',
                    label: 'Other Issue with iOS Device',
                }
            ],
            questions: [
                {
                    value: 'Where can I find my VIN?',
                    desc: 'The VIN, vehicle identification number, is a unique serial number for your vehicle. Your VIN can be found in a number of places, including printed inside the driver’s door (open the door and look at the edge), on the dashboard where the dash meets the windshield on the driver’s side, and in the engine bay or on the underside of the hood. The VIN should also be printed on your vehicle registration card and insurance policy. ',
                    sub_issue: ['subtype_vin']
                },
                // {
                //     value: 'I am unable to capture the VIN image properly?',
                //     desc: 'Your invoice has all the information properly structured. It starts with Summary of your RUC mileage and the RUC charges. ',
                //     sub_issue: ['subtype_vin']
                // },
                // {
                //     value: 'Where can I find my Odometer?',
                //     desc: 'Your invoice has all the information properly structured. It starts with Summary of your RUC mileage and the RUC charges. ',
                //     sub_issue: ['subtype_odometer']
                // },
                // {
                //     value: 'I am unable to capture the Odometer image properly?',
                //     desc: 'Your invoice has all the information properly structured. It starts with Summary of your RUC mileage and the RUC charges. ',
                //     sub_issue: ['subtype_odometer']
                // },
            ]
        },
        {
            value: 'issue_ruc_program',
            label: 'Program Issues',
            subcategories: [
                {
                    value: 'subtype_benefit_ruc',
                    label: 'Benefit',
                },
                {
                    value: 'subtype_ruc_program_other',
                    label: 'Other Program related issue',
                },
            ],
            questions: [
                {
                    value: 'What are the benefits to enroll in this program?',
                    desc: 'Enrollees may choose to enroll because: They drive fewer miles and want to save money, They want to spread their road usage payments gradually throughout the year among others.',
                    sub_issue: ['subtype_benefit_ruc']
                }
            ]
        },
        {
            value: 'issue_exit',
            label: 'Exit Issues',
            subcategories: [
                {
                    value: 'subtype_closing_account',
                    label: 'Closing account',
                },
                {
                    value: 'subtype_exit_other',
                    label: 'Other Exit related issue',
                },
            ],
            questions: [
                {
                    value: 'How do I close my account?',
                    desc: 'Please call us to speak with a customer service representive. CSR would be able to process the request. You will be notified when the request completes',
                    sub_issue: ['subtype_closing_account']
                }
            ]
        },
        {
            value: 'issue_other',
            label: 'Other',
            subcategories: [
                {
                    value: 'subtype_other_issue_other',
                    label: 'Enter your Question',
                }
            ],
            questions: []
        }
    ],

};

export const APP_CONFIG = new InjectionToken<IAppConfig>("app.config");
