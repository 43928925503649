import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { IAppConfig } from "../../config/app-config.interface";
import { APP_CONFIG } from "../../config/app-config.constants";
import * as FMS from '@core/models/finance.management.models';

@Injectable({
  providedIn: 'root',
})
export class FinanceManagementService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) protected config: IAppConfig,
  ) { }

  getAgencyRevenueReportSummary(summaryReportRequest: FMS.IFMS_SummaryReportRequest) {
    return this.http
      .post<FMS.IFMS_SummaryReportResponse>(environment.apiUrl + this.config.api_finance_management_summary_report, summaryReportRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAgencyJournal(journalRequest: FMS.IFMS_JournalRequest) {
    return this.http
      .post<FMS.IFMS_JournalResponse>(environment.apiUrl + this.config.api_finance_management_agency_journal, journalRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAgencyGlobalConfiguration() {
    return this.http
      .get<FMS.IFMS_AgencyGlobalConfigurationResponse>(environment.apiUrl + this.config.api_configuration_management_agency_global_configuration)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  saveAgencyGlobalConfiguration(productType: string, rucConfigObj: FMS.IFMS_AgencyGlobalConfigurationRecord) {
    return this.http
      .post<FMS.IFMS_RUCConfigurationResponse>(environment.apiUrl + this.config.api_configuration_management_agency_global_configuration, rucConfigObj)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAgencyConfigurationByProductType(productType: string) {
    return (productType === 'RUC') ? this.getAgencyRUCConfiguration() : this.getAgencyETollingConfiguration();
  }

  getAgencyRUCConfiguration() {
    return this.http
      .get<FMS.IFMS_RUCConfigurationResponse>(environment.apiUrl + this.config.api_finance_management_agency_ruc_configuration)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAgencyETollingConfiguration() {
    return this.http
      .get<FMS.IFMS_RUCConfigurationResponse>(environment.apiUrl + this.config.api_finance_management_agency_etolling_configuration)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  saveAgencyConfigurationByProductType(productType: string, rucConfigObj: FMS.IFMS_RUCConfigurationRecord[]) {
    return (productType === 'RUC') ? this.saveAgencyRUCConfiguration(rucConfigObj) : this.saveAgencyETollingConfiguration(rucConfigObj);
  }

  saveAgencyRUCConfiguration(rucConfigObj: FMS.IFMS_RUCConfigurationRecord[]) {
    return this.http
      .post<FMS.IFMS_RUCConfigurationResponse>(environment.apiUrl + this.config.api_finance_management_agency_ruc_configuration, rucConfigObj)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  saveAgencyETollingConfiguration(rucConfigObj: FMS.IFMS_RUCConfigurationRecord[]) {
    return this.http
      .post<FMS.IFMS_RUCConfigurationResponse>(environment.apiUrl + this.config.api_finance_management_agency_etolling_configuration, rucConfigObj)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAgencyRUCChargesConfiguration() {
    return this.http
      .get<FMS.IFMS_RUCChargesConfigurationResponse>(environment.apiUrl + this.config.api_finance_management_agency_ruc_charges_configuration)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  saveAgencyRUCChargesConfiguration(rucConfigObj: FMS.IFMS_SaveRUCChargeConfigurationRequest) {
    return this.http
      .post<FMS.IFMS_RUCChargesConfigurationResponse>(environment.apiUrl + this.config.api_finance_management_agency_ruc_charges_configuration, rucConfigObj)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /* Summary Reports */
  getSummaryReportByMroType(reportRequest: FMS.IFMS_ReportRequest) {
    return this.http
      .post<FMS.IFMS_SummaryReportByMroTypeResponse>(environment.apiUrl + this.config.api_finance_management_summary_report_by_mro_type, reportRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getSummaryReportByTransactionType(reportRequest: FMS.IFMS_ReportRequest) {
    return this.http
      .post<FMS.IFMS_SummaryReportByTransactionTypeResponse>(environment.apiUrl + this.config.api_finance_management_summary_report_by_transaction_type, reportRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getSummaryReportOfMileageAdjustment(reportRequest: FMS.IFMS_ReportRequest) {
    return this.http
      .post<FMS.IFMS_SummaryReportOfMileageAdjustmentResponse>(environment.apiUrl + this.config.api_finance_management_summary_report_mileage_adjustment, reportRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getSummaryReportOfDataCollectionMileage(reportRequest: FMS.IFMS_ReportRequest) {
    return this.http
      .post<FMS.IFMS_SummaryReportOfMileageAdjustmentResponse>(environment.apiUrl + this.config.api_finance_management_summary_report_datacollection_mileage, reportRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getSummaryReportOfNetRucMileage(reportRequest: FMS.IFMS_ReportRequest) {
    return this.http
      .post<FMS.IFMS_SummaryReportOfNetRUCMileageResponse>(environment.apiUrl + this.config.api_finance_management_summary_report_net_ruc_mileage, reportRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAgencyPayables(journalRequest: FMS.IFMS_ReportRequest) {
    return this.http
      .post<FMS.IFMS_AgencyFinancesResponse>(environment.apiUrl + this.config.api_finance_management_agency_payables, journalRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAgencyReceivables(journalRequest: FMS.IFMS_ReportRequest) {
    return this.http
      .post<FMS.IFMS_AgencyFinancesResponse>(environment.apiUrl + this.config.api_finance_management_agency_recievables, journalRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAgencyAdjustments(journalRequest: FMS.IFMS_ReportRequest) {
    return this.http
      .post<FMS.IFMS_AgencyFinancesResponse>(environment.apiUrl + this.config.api_finance_management_agency_adjustments, journalRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

}