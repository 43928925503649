import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConsumerAuthService } from '../service/consumer.auth.service';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class ConsumerAuthGuard {
  isConsumerPortal = environment.consumerPortal ? true : false;
  
  constructor(private consumerAuthService: ConsumerAuthService, private router: Router) { }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.consumerAuthService.geotollConsumerValue && this.consumerAuthService.geotollConsumerValue.id && this.isConsumerPortal) {
      return true;
    }
    this.router.navigate(['/home']);
    return false;
  }
}
