import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public products: string[] = ['am', 'fm', 'cm'];
 
  public setProduct(product: string) {
    localStorage.setItem('productCode', product);
  }
}
