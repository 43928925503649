import { Page404Component } from './authentication/page404/page404.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { ConsumerLayoutComponent } from './layout/app-layout/consumer-layout/consumer-layout.component';
import { FinanceManagementLayoutComponent } from './layout/app-layout/finance-management/finance-management-layout.component';
import { AccountManagementLayoutComponent } from './layout/app-layout/account-management/account-management-layout.component';
import { ConsumerMyaccountLayoutComponent } from './layout/app-layout/consumer-myaccount/consumer-myaccount-layout.component';
import { ConsumerVerifyMigrationLayoutComponent } from './layout/app-layout/consumer-myaccount/consumer-verify-migration-layout.component';
import { ConfigurationManagementLayoutComponent } from './layout/app-layout/configuration-management/configuration-management-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { ConsumerAuthGuard } from './core/guard/consumer.auth.guard';
import { AgencyPortalGuard } from './core/guard/agency.portal.guard';
import { ConsumerPortalGuard } from './core/guard/consumer.portal.guard';
import { ClientAgencyPortalGuard } from './core/guard/client.portal.guard';
import { AMSPortalGuard } from './core/guard/ams.portal.guard';
import { Role } from './core/models/role';
const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/authentication/signin',
        pathMatch: 'full'
      },
      {
        path: 'account-management',
        component: AccountManagementLayoutComponent,
        canActivate: [AuthGuard, AMSPortalGuard, AgencyPortalGuard],
        title: 'GeoToll Account Management System',
        data: {
          role: [Role.Admin, Role.Agency],
        },
        loadChildren: () =>
          import('./modules/account-management/account-management.module').then((m) => m.AccountManagementModule),
      },
      {
        path: 'agency-management',
        component: AccountManagementLayoutComponent,
        canActivate: [AuthGuard, AMSPortalGuard, AgencyPortalGuard],
        title: 'GeoToll Agency Management System',
        data: {
          role: [Role.Admin, Role.Agency],
        },
        loadChildren: () =>
          import('./modules/agency-management/agency-management.module').then((m) => m.AgencyManagementModule),
      },
      {
        path: 'client-management',
        component: AccountManagementLayoutComponent,
        canActivate: [AuthGuard, AMSPortalGuard, ClientAgencyPortalGuard],
        title: 'GeoToll Client Management System',
        data: {
          role: [Role.Admin, Role.Agency],
        },
        loadChildren: () =>
          import('./modules/client-management/client-management.module').then((m) => m.ClientAgencyManagementModule),
      },
      {
        path: 'finance-management',
        component: FinanceManagementLayoutComponent,
        canActivate: [AuthGuard, AMSPortalGuard, AgencyPortalGuard],
        title: 'GeoToll Finance Management System',
        data: {
          role: [Role.Admin, Role.Agency],
        },
        loadChildren: () =>
          import('./modules/finance-management/finance-management.module').then((m) => m.FinanceManagementModule),
      },
      {
        path: 'configuration-management',
        component: ConfigurationManagementLayoutComponent,
        canActivate: [AuthGuard, AMSPortalGuard, AgencyPortalGuard],
        title: 'GeoToll Configuration Management System',
        data: {
          role: [Role.Admin, Role.Agency],
        },
        loadChildren: () =>
          import('./modules/configuration-management/configuration-management.module').then((m) => m.ConfigurationManagementModule),
      }
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    canActivate: [AMSPortalGuard],
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'home',
    component: ConsumerLayoutComponent,
    canActivate: [ConsumerPortalGuard],
    title: 'GeoToll Consumer Portal',
    loadChildren: () =>
      import('./modules/consumer-portal/consumer-portal.module').then(
        (m) => m.ConsumerPortalModule
      ),
  },
  {
    path: 'myaccount',
    component: ConsumerMyaccountLayoutComponent,
    canActivate: [ConsumerAuthGuard, ConsumerPortalGuard],
    title: 'GeoToll Account Overview',
    loadChildren: () =>
      import('./modules/consumer-myaccount/consumer-myaccount.module').then(
        (m) => m.ConsumerMyaccountModule
      ),
  },
  {
    path: 'user-migration',
    component: ConsumerVerifyMigrationLayoutComponent,
    canActivate: [ConsumerAuthGuard, ConsumerPortalGuard],
    loadChildren: () =>
      import('./modules/consumer-myaccount/consumer-myaccount.module').then(
        (m) => m.ConsumerMyaccountModule
      ),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  // imports: [RouterModule.forRoot(routes, {})],
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
