import { RouteInfo } from '../sidebar.metadata';

export const AM_ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Agency Dashboards',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    allowClient: true
  },
  {
    path: '/agency-management/consumers-dashboard',
    title: 'Accounts Dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'people_alt',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    allowClient: true
  },
  {
    path: '/agency-management/vehicles-dashboard',
    title: 'Vehicles Dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'directions_car',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    allowClient: false
  },
  {
    path: '',
    title: 'Consumer Accounts',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    allowClient: false
  },
  {
    path: '/account-management/account/search',
    title: 'Account Search',
    iconType: 'material-icons-two-tone',
    icon: 'search',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    allowClient: false
  },
  {
    path: '/agency-management/pending-approval',
    title: 'Pending Approvals',
    iconType: 'material-icons-two-tone',
    icon: 'person_pin_circle',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    allowClient: false
  },
  {
    path: '/account-management/account/add',
    title: 'Register Account',
    iconType: 'material-icons-two-tone',
    icon: 'person_add',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    allowClient: false
  },
  {
    path: '/agency-management/import-accounts',
    title: 'Import Users',
    iconType: 'material-icons-two-tone',
    icon: 'import_contacts',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    allowClient: false
  },
  {
    path: '',
    title: 'Pending Actions',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    allowClient: false
  },
  {
    path: '/agency-management/pending-odometer-approval',
    title: 'RUC Odometer Review',
    iconType: 'material-icons-two-tone',
    icon: 'keyboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    allowClient: false
  },
  {
    path: '/agency-management/pending-vin-approval',
    title: 'RUC VIN Review',
    iconType: 'material-icons-two-tone',
    icon: 'monochrome_photos',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    allowClient: false
  },
  {
    path: '',
    title: 'Consumer Account Details',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    allowClient: false
  },
  {
    path: '',
    title: 'Account Details',
    iconType: 'material-icons-two-tone',
    icon: 'person',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [
      {
        path: '/account-management/account/info',
        title: 'Information',
        iconType: 'material-icons-two-tone',
        icon: 'info',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/account-management/account/vehicles',
        title: 'Vehicles',
        iconType: 'material-icons-two-tone',
        icon: 'directions_car',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/account-management/account/contacts',
        title: 'Contacts',
        iconType: 'material-icons-two-tone',
        icon: 'contacts',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/account-management/account/correspondences',
        title: 'Correspondences',
        iconType: 'material-icons-two-tone',
        icon: 'contact_mail',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/account-management/account/notes',
        title: 'Notes',
        iconType: 'material-icons-two-tone',
        icon: 'notes',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/account-management/account/devices',
        title: 'Devices',
        iconType: 'material-icons-two-tone',
        icon: 'devices',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/account-management/account/wallet',
        title: ' Wallet',
        iconType: 'material-icons-two-tone',
        icon: 'account_balance_wallet',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/account-management/account/statements',
        title: 'Statements',
        iconType: 'material-icons-two-tone',
        icon: 'file_download',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/account-management/account/ruc-transactions',
        title: 'RUC Transactions',
        iconType: 'material-icons-two-tone',
        icon: 'paid',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/account-management/account/etoll-transactions',
        title: 'E-Toll Transactions',
        iconType: 'material-icons-two-tone',
        icon: 'paid',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/account-management/account/payment-methods',
        title: 'Payment Methods',
        iconType: 'material-icons-two-tone',
        icon: 'payments',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/account-management/account/audit-history',
        title: 'Audit History',
        iconType: 'material-icons-two-tone',
        icon: 'history',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/account-management/account/login-history',
        title: 'Login History',
        iconType: 'material-icons-two-tone',
        icon: 'history',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin', 'agency'],
        submenu: [],
      },
      {
        path: '/account-management/account/cases',
        title: 'Cases',
        iconType: 'material-icons-two-tone',
        icon: 'cases',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: 'badge bg-orange sidebar-badge float-end',
        role: ['admin', 'agency'],
        submenu: [],
      },
      // {
      //   path: '/account-management/account/history',
      //   title: 'History',
      //   iconType: 'material-icons-two-tone',
      //   icon: 'history',
      //   class: '',
      //   groupTitle: false,
      //   badge: 'x',
      //   badgeClass: 'badge bg-orange sidebar-badge float-end',
      //   role: ['admin', 'agency'],
      //   submenu: [],
      // },
    ],
    allowClient: false
  },
];

export const CLIENT_AM_ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Agency Dashboard',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    allowClient: true
  },
  {
    path: '/client-management/consumers-dashboard',
    title: 'Accounts Dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'people_alt',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    allowClient: true
  },
];