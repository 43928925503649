import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeoTollUser, GeoTollAuth } from '../models/user';
import { environment } from 'environments/environment';
import { IAppConfig } from "@config/app-config.interface";
import { APP_CONFIG } from "@config/app-config.constants";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private geotollUserSubject: BehaviorSubject<GeoTollUser>;
  public geotollUser: Observable<GeoTollUser>;
  public geotollAgency: Observable<any>;
  private geotollAgencySubject: BehaviorSubject<any>;
  private refreshTokenTimeout = null;
  private refreshTokenGap = 300000; // show modal 5min before token expiry

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) protected config: IAppConfig
  ) {
    this.geotollUserSubject = new BehaviorSubject<GeoTollUser>(
      JSON.parse(localStorage.getItem('geotoll.user') || '{}')
    );
    this.geotollAgencySubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('geotoll.agency') || '{}')
    );
    this.geotollUser = this.geotollUserSubject.asObservable();
  }

  public get geotollUserValue(): GeoTollUser {
    return this.geotollUserSubject.value;
  }

  public get geotollAgencyValue(): any {
    return this.geotollAgencySubject.value;
  }


  login(usertype: string, username: string, password: string) {
    let url = this.config.api_agency_login;
    url = (usertype === "admin") ? this.config.api_agency_login : url;
    url = (usertype === "agency") ? this.config.api_agency_login : url;
    url = environment.apiUrl + url;

    return this.http
      .post<any>(url, {
        email: username,
        password: password
      })
      .pipe(
        map((response) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('geotoll.user', JSON.stringify(response.data.user));
          this.geotollUserSubject.next(response.data.user);
          localStorage.setItem('geotoll.agency', JSON.stringify(response.data.agency));
          localStorage.setItem('geotoll.config', JSON.stringify(response.data.config))
          this.geotollAgencySubject.next(response.data.agency);
          return response.data.user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('geotoll.user');
    localStorage.removeItem('geotoll.agency');
    localStorage.removeItem('geotoll.config');
    localStorage.removeItem('ams.active.user');
    localStorage.removeItem('ams.active.userinformation');
    localStorage.removeItem('productCode');
    this.geotollUserSubject.next(this.geotollUserValue);
    return of({ success: false });
  }
}
