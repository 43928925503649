/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { BaseSidebarComponent } from '../base-sidebar.component';
import { AM_ROUTES, CLIENT_AM_ROUTES } from './account-management-sidebar';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-account-management-sidebar',
  templateUrl: './account-management-sidebar.component.html',
  styleUrls: ['./account-management-sidebar.component.scss'],
})
export class AccountManagementSidebarComponent extends BaseSidebarComponent implements OnInit {

  ngOnInit() {
    // console.log("Client Access  :" + environment.clientAccess);
    const routesData = (environment.clientAccess) ? CLIENT_AM_ROUTES : AM_ROUTES;
    if (this.authService.geotollUserValue && !this.isConsumerPortal) {
      const userRole = this.authService.geotollUserValue.role;
      this.userFullName =
        this.authService.geotollUserValue.fname +
        ' ' +
        this.authService.geotollUserValue.lname;

      this.sidebarItems = routesData.filter(
        (x) => x.role.indexOf(userRole) !== -1 || x.role.indexOf('All') !== -1
      );
    }

    if (environment.clientAccess) this.sidebarItems = this.sidebarItems.filter((x) => x.allowClient === true);

    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }

}
