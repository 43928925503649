<nav #navbar class="navbar active {{envConfig.portalDataInfo.agencyClassOverride}}">
  <div class="container-fluid">
    <div class="navbar-header {{envConfig.portalDataInfo.agencyClassOverride}}">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand {{envConfig.portalDataInfo.agencyClassOverride}}" routerLink={{homePage}}>
        <img src="assets/images/logos/{{envConfig.portalDataInfo.headerLogo}}" alt="" />
      </a>
    </div>

    <div class="collapse navbar-collapse" [ngClass]="isNavbarCollapsed ? '' : 'show'">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-button (click)="callSidemenuCollapse()" class="sidemenu-collapse nav-notification-icons"> <i
              class="material-icons-outlined icon-color">
              menu
            </i></button>
        </li>
      </ul>

      <ul class="nav navbar-nav navbar-right">
        <li class="nav-item user_profile" *ngIf="accountClosingDateStr">
          <button mat-button>
            <div class="badge col-red bold">Your account is closing on: {{accountClosingDateStr | date:'MM/dd/yyyy'}}</div>
          </button>
        </li>

        <li *ngIf="isConsumerLoggedIn && userFullName !== ''" class="nav-item user_profile">
          <button mat-button [matMenuTriggerFor]="profilemenu">
            <span>{{userFullName}}</span>
          </button>
          <mat-menu #profilemenu="matMenu" class="profile-menu">
            <div class="noti-list">
              <div class="menu ">
                <div class="user_dw_menu">
                  <button mat-menu-item (click)="logout()">
                    <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Logout
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>