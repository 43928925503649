import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { IAppConfig } from "../../config/app-config.interface";
import { APP_CONFIG } from "../../config/app-config.constants";
import { BehaviorSubject } from 'rxjs';
import * as AMS from '@core/models/account.management.models';
import * as FMS from '@core/models/finance.management.models';
import * as CMS from '@core/models/configuration.management.models';

@Injectable({
  providedIn: 'root',
})
export class AccountManagementService {
  private activeConsumerSubject: BehaviorSubject<AMS.IAccountManagementConsumerInformation>;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) protected config: IAppConfig,
  ) {
    this.activeConsumerSubject = new BehaviorSubject<AMS.IAccountManagementConsumerInformation>(
      JSON.parse(localStorage.getItem('ams.active.userinformation') || '{}')
    );
  }

  public get getActiveConsumerInfo(): AMS.IAccountManagementConsumerInformation {
    return this.activeConsumerSubject.value;
  }

  isConsumerPortal() {
    return environment.consumerPortal ? true : false;
  }
  getUSAStates() {
    return this.config.usa_states;
  }

  getPreferredModesOfCommunication() {
    return this.config.preferred_communication_modes;
  }

  getVehicleInactivationReasons() {
    return this.config.inactivate_vehicle_reason_codes;
  }

  getVehicleUpdateMROTypeReasons() {
    return this.config.vehicle_update_mro_type_reason_codes;
  }

  getContactInactivationReasons() {
    return this.config.inactivate_contact_reason_codes;
  }

  getContactAddressInactivationReasons() {
    return this.config.inactivate_contact_address_reason_codes;
  }

  getPaymentMethodInactivationReasons() {
    return this.config.inactivate_payment_method_codes;
  }

  getUserAccountInactivationReasons() {
    return this.config.inactivate_user_account_codes;
  }

  getConsumerCallReasons() {
    return this.config.consumer_customer_support_call_reasons;
  }

  getAvailableMROTypeOptions() {
    return this.config.allowed_mro_types_options;
  }

  getTransactionTypesOptions() {
    return this.config.transaction_type_options;
  }

  /* Customer support New functions START */
  getCustomerSupportIssueTypesInfo() {
    return this.config.customer_support_issue_types_info;
  }

  getCustomerSupportIssueTypeObject(issueType: string) {
    const issueTypesInfo = this.getCustomerSupportIssueTypesInfo();
    return issueTypesInfo.filter(item => item.value === issueType);
  }

  getCustomerSupportIssueSubTypeLabel(issueSubType: string) {
    const issueTypesInfo = this.getCustomerSupportIssueTypesInfo();
    let label = 'Unknown';
    issueTypesInfo.forEach(issueObj => {
      issueObj.subcategories!.forEach(subType => {
        if (subType.value === issueSubType) label = subType.label;
      });
    });
    return label;
  }
  /* Customer support New functions END */

  getAllowedMROTypeOptions(vehicleYear: number) {
    const availableMROOptions = this.getAvailableMROTypeOptions();

    if (vehicleYear > 2013) {
      availableMROOptions.forEach(mro => {
        if (mro.type === 'smartapp') mro.enabled = true;
        if (mro.type === 'telematics') mro.enabled = true;
      });
    } else {
      availableMROOptions.forEach(mro => {
        if (mro.type === 'smartapp') mro.enabled = false;
        if (mro.type === 'telematics') mro.enabled = false;
      });
    }

    return availableMROOptions;
  }

  getMonths() {
    return this.config.months;
  }

  getYears() {
    return [
      { val: 2023, label: "2023" },
      { val: 2024, label: "2024" },
    ]
  }

  getCardExpirationYears() {
    return this.config.card_years;
  }

  getCountries() {
    return this.config.countries;
  }

  searchConsumers(filterObj: any) {
    return this.http
      .post<AMS.IAccountManagementSearchResultsGeneric>(environment.apiUrl + this.config.api_account_management_search_accounts, filterObj)
      .pipe(
        map((users) => {
          return users;
        })
      );
  }

  getConsumerInfo(userId: number) {
    return this.http
      .post<AMS.IAccountManagementGetConsumerInformation>(environment.apiUrl + this.config.api_account_management_consumer_info, { consumer_id: userId })
      .pipe(
        map((response) => {
          localStorage.setItem('ams.active.userinformation', JSON.stringify(response.data));
          this.activeConsumerSubject.next(response.data);
          return response;
        })
      );
  }

  getConsumerVehicles(userId: number) {
    return this.http
      .post<AMS.IAccountManagementConsumerVehiclesGeneric>(environment.apiUrl + this.config.api_account_management_consumer_vehicles, { consumer_id: userId })
      .pipe(
        map((vehicles) => {
          return vehicles;
        })
      );
  }

  addConsumerVehicle(vehicleObj: AMS.IAddAccountManagementConsumerVehicle) {
    return this.http
      .post<AMS.IAccountManagementConsumerVehicle>(environment.apiUrl + this.config.api_account_management_add_consumer_vehicle, vehicleObj)
      .pipe(
        map((vehicle) => {
          return vehicle;
        })
      );
  }

  getConsumerVehicleOdometerHistory(accountManagementConsumerRecordRequest: AMS.IAccountManagementConsumerRecordRequest) {
    return this.http
      .post<AMS.IAccountManagementConsumerVehicleOdometerHistoryGeneric>(environment.apiUrl + this.config.api_account_management_consumer_vehicle_odometer_history, accountManagementConsumerRecordRequest)
      .pipe(
        map((vehicles) => {
          return vehicles;
        })
      );
  }

  getConsumerContacts(userId: number) {
    return this.http
      .post<AMS.IViewAccountManagementConsumerContacts>(environment.apiUrl + this.config.api_account_management_consumer_contacts, { consumer_id: userId })
      .pipe(
        map((contacts) => {
          return contacts;
        })
      );
  }

  addConsumerContact(contactObj: AMS.IAddAccountManagementConsumerContact) {
    return this.http
      .post<AMS.IViewAccountManagementConsumerContact>(environment.apiUrl + this.config.api_account_management_consumer_contact_add, contactObj)
      .pipe(
        map((contact) => {
          return contact;
        })
      );
  }

  getConsumerContactAddresses(userId: number) {
    return this.http
      .post<AMS.IViewAccountManagementConsumerContactAddresses>(environment.apiUrl + this.config.api_account_management_consumer_contact_addresses, { consumer_id: userId })
      .pipe(
        map((addresses) => {
          return addresses;
        })
      );
  }

  addConsumerContactAddress(addressObj: AMS.IAddAccountManagementConsumerContactAddress) {
    return this.http
      .post<AMS.IViewAccountManagementConsumerContactAddress>(environment.apiUrl + this.config.api_account_management_consumer_contact_address_add, addressObj)
      .pipe(
        map((address) => {
          return address;
        })
      );
  }

  decodeVIN(vin: string, isLoggedInUser: boolean) {
    const api = (isLoggedInUser) ? this.config.api_vin_decode : this.config.api_vin_decode_guest;
    return this.http
      .get<any>(environment.apiUrl + api + vin + "/" + environment.portalDataInfo.agencyId)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  registerUser(registerConsumerRequest: AMS.IRegisterConsumerRequest) {
    const apiUrl = (environment.consumerPortal) ? this.config.api_preregister_new_user_self : this.config.api_register_new_user;
    // console.log("Pre Register " + environment.consumerPortal);
    return this.http
      .post<AMS.IAgencyConsumerRegistrationDecisionResponse>(environment.apiUrl + apiUrl, registerConsumerRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  completeUserRegistrationMigration(registerConsumerRequest: AMS.IRegisterConsumerRequest) {
    return this.http
      .post<AMS.IAgencyConsumerRegistrationDecisionResponse>(environment.apiUrl + this.config.api_complete_user_registration_migration, registerConsumerRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }


  getPendingApprovalConsumersList(filterPendingApprovalConsumers: AMS.IFilterPendingApprovalConsumers) {
    return this.http
      .post<AMS.IAMSAgencyUserVehiclesStatusData>(environment.apiUrl + this.config.api_accounts_pending_admin_approval, filterPendingApprovalConsumers)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  approvePendingUser(approvePendingConsumerRequest: AMS.IApprovePendingConsumerRequest) {
    return this.http
      .post<AMS.IAgencyConsumerRegistrationDecisionResponse>(environment.apiUrl + this.config.api_approve_pending_user_vehicle, approvePendingConsumerRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  transmitToAgency(approvePendingConsumerRequest: AMS.IApprovePendingConsumerRequest) {
    return this.http
      .post<AMS.IAgencyConsumerRegistrationDecisionResponse>(environment.apiUrl + this.config.api_accounts_new_admin_approval_transmit, approvePendingConsumerRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  rejectPendingUser(rejectPendingConsumerRequest: AMS.IRejectPendingConsumerRequest) {
    return this.http
      .post<AMS.IAgencyConsumerRegistrationDecisionResponse>(environment.apiUrl + this.config.api_reject_pending_user_vehicle, rejectPendingConsumerRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getVehicleMileageHistory(userId: number, vehicleId: number, startDate: string, endDate: string) {
    return this.http
      .post<AMS.IViewVehicleMileageHistory>(environment.apiUrl + this.config.api_account_management_consumer_vehicle_trip_history, {
        consumer_id: userId,
        user_id: userId,
        vehicle_id: vehicleId,
        start_date: startDate,
        end_date: endDate
      })
      .pipe(
        map((mileageHistory) => {
          return mileageHistory;
        })
      );
  }

  getConsumerCorrespondences(userId: number) {
    return this.http
      .post<AMS.IAccountManagementConsumerCorrespondences>(environment.apiUrl + this.config.api_account_management_consumer_correspondences, { consumer_id: userId })
      .pipe(
        map((correspondences) => {
          return correspondences;
        })
      );
  }

  getConsumerNotes(userId: number) {
    return this.http
      .post<AMS.IAccountManagementConsumerCorrespondences>(environment.apiUrl + this.config.api_account_management_consumer_notes, { consumer_id: userId })
      .pipe(
        map((correspondences) => {
          return correspondences;
        })
      );
  }

  deleteConsumerRecord(deleteObj: AMS.IAccountManagementConsumerDeleteRecordRequest) {
    console.log(deleteObj);
    return this.http
      .post<AMS.IAccountManagementConsumerDeleteRecordResponse>(environment.apiUrl + this.config.api_account_management_delete_consumer_record, deleteObj)
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        })
      );
  }

  getConsumerDevices(userId: number) {
    return this.http
      .post<AMS.IAccountManagementConsumerDevices>(environment.apiUrl + this.config.api_account_management_consumer_devices, { consumer_id: userId })
      .pipe(
        map((devices) => {
          return devices;
        })
      );
  }

  getConsumerLoginHistory(userId: number) {
    return this.http
      .post<AMS.IAccountManagementConsumerLoginHistory>(environment.apiUrl + this.config.api_account_management_consumer_login_history, { consumer_id: userId })
      .pipe(
        map((devices) => {
          return devices;
        })
      );
  }

  getAgencyConsumerAccountsStatusData(agencyId?: number) {
    return this.http
      .post<AMS.IAMSAgencyUserVehiclesStatusData>(environment.apiUrl + this.config.api_account_management_agency_users_account_status, { agency_id: agencyId })
      .pipe(
        map((devices) => {
          return devices;
        })
      );
  }

  getAgencyConsumerVehiclesStatsData(agencyId?: number) {
    return this.http
      .post<AMS.IAMSAgencyVehicleStatsData>(environment.apiUrl + this.config.api_account_management_agency_users_vehicles_stats, { agency_id: agencyId })
      .pipe(
        map((devices) => {
          return devices;
        })
      );
  }

  getAgencyConsumerTransactions(vehicleId: number, startDate: string, endDate: string) {
    return this.http
      .post<AMS.IAMSAgencyConsumerTransactions>(environment.apiUrl + this.config.api_account_management_consumer_transactions, {
        vehicle_id: vehicleId,
        startDatePeriod: startDate,
        endDatePeriod: endDate
      })
      .pipe(
        map((transactions) => {
          return transactions;
        })
      );
  }

  getConsumerPaymentProfiles(userId: number) {
    return this.http
      .post<AMS.IAccountManagementConsumerPaymentProfiles>(environment.apiUrl + this.config.api_account_management_agency_users_payment_profiles, { consumer_id: userId })
      .pipe(
        map((profiles) => {
          return profiles;
        })
      );
  }

  addConsumerPaymentProfile(addPaymentProfileRequest: AMS.IAccountManagementAddConsumerPaymentProfileRequest) {
    return this.http
      .post<AMS.IGenericResponse>(environment.apiUrl + this.config.api_account_management_agency_user_add_payment_profile, addPaymentProfileRequest)
      .pipe(
        map((profile) => {
          return profile;
        })
      );
  }

  getConsumerAuditHistory(requestObj: AMS.IAccountManagementConsumerAuditHistoryRequest) {
    return this.http
      .post<AMS.IAccountManagementConsumerAuditHistoryResults>(environment.apiUrl + this.config.api_account_management_consumer_audit_history, requestObj)
      .pipe(
        map((devices) => {
          return devices;
        })
      );
  }

  csGenerateConsumerOTP(csObj: AMS.IAMSCustomerServiceConsumerRequest) {
    return this.http
      .post<AMS.IAMSCustomerServiceConsumerRequestResponse>(environment.apiUrl + this.config.api_cs_consumer_generate_otp, csObj)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  csVerifyConsumerOTP(csObj: AMS.IAMSCustomerServiceConsumerRequest) {
    return this.http
      .post<AMS.IAMSCustomerServiceConsumerRequestResponse>(environment.apiUrl + this.config.api_cs_consumer_verify_otp, csObj)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  updateVehicleMROType(updateObj: AMS.IUpdateVehicleMROTypeRequest) {
    return this.http
      .put<AMS.IAMSGenericUpdateRequestResponse>(environment.apiUrl + this.config.api_consumer_vehicle_update_mro_type, updateObj)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getAgencyConsumerStatements(consumerStatementListRequest: AMS.IAMSConsumerStatementListRequest) {
    return this.http
      .post<AMS.IAMSAgencyConsumerStatementsList>(environment.apiUrl + this.config.api_account_management_consumer_statements_list, consumerStatementListRequest)
      .pipe(
        map((statements) => {
          return statements;
        })
      );
  }

  getConsumerStatementDetails(consumerStatementDetailRequest: AMS.IAMSConsumerStatementDetailRequest) {
    return this.http
      .post<AMS.IAMSAgencyConsumerStatementDetailsResponse>(environment.apiUrl + this.config.api_account_management_consumer_statement_details, consumerStatementDetailRequest)
      .pipe(
        map((statement) => {
          return statement;
        })
      );
  }

  getConsumerStatementDetailsPdf(consumerStatementDetailRequest: AMS.IAMSConsumerStatementDetailRequest) {
    return this.http.post(environment.apiUrl + this.config.api_account_management_consumer_statement_details, consumerStatementDetailRequest, { responseType: 'blob' });
  }

  getAgencyConsumerSupportTickets(viewZendeskTicketRequest: AMS.ICSViewZendeskTicketRequest) {
    return this.http
      .post<AMS.ICSZendeskTicketListResponse>(environment.apiUrl + this.config.api_customer_support_view_tickets, viewZendeskTicketRequest)
      .pipe(
        map((tickets) => {
          return tickets;
        })
      );
  }

  addAgencyConsumerSupportTickets(addZendeskTicketRequest: AMS.ICSAddContactUsTicketRequest) {
    return this.http
      .post<AMS.ICSZendeskTicketAddOrUpdateResponse>(environment.apiUrl + this.config.api_customer_support_create_ticket, addZendeskTicketRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAgencyConsumerSupportTicketDetails(viewZendeskTicketRequest: AMS.ICSViewZendeskTicketRequest) {
    return this.http
      .post<AMS.ICSZendeskTicketCommentsResponse>(environment.apiUrl + this.config.api_customer_support_view_ticket_comments, viewZendeskTicketRequest)
      .pipe(
        map((ticketComments) => {
          return ticketComments;
        })
      );
  }

  updateAgencyConsumerSupportTickets(updateZendeskTicketRequest: AMS.ICSUpdateContactUsTicketRequest) {
    return this.http
      .post<AMS.ICSZendeskTicketAddOrUpdateResponse>(environment.apiUrl + this.config.api_customer_support_update_ticket, updateZendeskTicketRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getRulesData() {
    return this.http
      .get<AMS.ILookupRulesDataResponse>(environment.apiUrl + this.config.api_agency_get_rules_data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  processMileageAdjustment(adjustmentRecordRequest: AMS.IAdjustmentRecordRequest) {
    return this.http
      .post<AMS.ICSZendeskTicketAddOrUpdateResponse>(environment.apiUrl + this.config.api_consumer_vehicle_add_miles_adjustment, adjustmentRecordRequest)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getConsumerProfileDashboardData(userId: number) {
    return this.http
      .post<AMS.IAccountManagementConsumerDashboardDataResponse>(environment.apiUrl + this.config.api_account_management_consumer_profile_dashboard, { consumer_id: userId })
      .pipe(
        map((devices) => {
          return devices;
        })
      );
  }

  processTelematicsRegistration(vehicleId: number) {
    return this.http
      .get<AMS.IAMSTelematicsStartEnrollResponse>(environment.apiUrl + this.config.api_account_management_vehicle_start_telematics_enrollment + vehicleId)
      .pipe(
        map((vehicle) => {
          return vehicle;
        })
      );
  }

  completeTelematicsRegistration(telematicsData: any) {
    return this.http
      .post<AMS.IAMSTelematicsCompleteEnrollResponse>(environment.apiUrl + this.config.api_account_management_vehicle_complete_telematics_enrollment, telematicsData)
      .pipe(
        map((vehicle) => {
          return vehicle;
        })
      );
  }

  getConsumerWalletActivity(walletActivityRequest: AMS.IAMSGetAgencyConsumerWalletActivityRequest) {
    const wallet_api_end_point = "/account-management/user/" + walletActivityRequest.user_id + "/wallet/activity";
    return this.http
      .post<AMS.IAMSGetAgencyConsumerWalletActivityResponse>(environment.apiUrl + wallet_api_end_point, walletActivityRequest)
      .pipe(
        map((activity) => {
          return activity;
        })
      );
  }

  addConsumerWalletPayment(postAgencyConsumerWalletPaymentRequest: AMS.IAMSPostAgencyConsumerWalletPaymentRequest) {
    const wallet_api_end_point = "/account-management/user/" + postAgencyConsumerWalletPaymentRequest.user_id + "/wallet/payment";
    return this.http
      .post<AMS.IAMSPostAgencyConsumerWalletPaymentResponse>(environment.apiUrl + wallet_api_end_point, postAgencyConsumerWalletPaymentRequest)
      .pipe(
        map((activity) => {
          return activity;
        })
      );
  }

  getConsumerVehicleMroTypesEligibility(vinData: AMS.IFetchedVinData) {
    return this.http
      .post<AMS.IAMSVehicleMROTypesEligibilityResponse>(environment.apiUrl + this.config.api_account_management_consumer_vehicle_mro_types_eligibility, vinData)
      .pipe(
        map((vinData) => {
          return vinData;
        })
      );
  }

  getAgencyConsumerSignUpConfiguration() {
    const api = this.config.api_agency_registration_config + environment.portalDataInfo.agencyId;
    return this.http
      .get<FMS.IFMS_AgencySignUpConfigurationResponse>(environment.apiUrl + api)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAgencyConsumerPortalConfiguration() {
    const api = this.config.api_agency_consumer_portal_config;
    return this.http
      .get<FMS.IFMS_ConsumerPortalConfigResponse>(environment.apiUrl + api)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAgencyConsumerETollingTransactions(vehicleId: number, startDate: string, endDate: string) {
    return this.http
      .post<CMS.ICMSTollplazaTransactionsListResponse>(environment.apiUrl + this.config.api_consumer_tollplaza_transactions_list, {
        vehicle_id: vehicleId,
        start_date: startDate,
        end_date: endDate
      })
      .pipe(
        map((transactions) => {
          return transactions;
        })
      );
  }

  /* Date functions */
  _getCurrentMonth() {
    const d = new Date();
    return d.getMonth() + 1;
  }

  _getCurrentYear() {
    const d = new Date();
    return d.getFullYear();
  }

  _daysInMonth = (year: number, month: number) => new Date(year, month, 0).getDate();

  _getNumberOfDaysInMonth(year: number, month: number) {
    return this._daysInMonth(year, month);
  }

  _getDaysInCurrentMonth() {
    return this._getDaysArrForMonth(this._getCurrentMonth());
  }

  _getDaysArrForMonth(month: number) {
    const year = this._getCurrentYear();
    const numDays = this._getNumberOfDaysInMonth(year, month);
    const yearStr = year.toString().substring(4, 2);
    const daysArr = [];
    for (let i = 1; i <= numDays; i++) {
      const d = month + "/" + i + "/" + yearStr;
      daysArr.push(d);
    }
    return daysArr;
  }

  _getEndDateByMonthId(month: number): string {
    const year = this._getCurrentYear();
    const numDays = this._getNumberOfDaysInMonth(year, month);
    const monthStr = (month < 10) ? "0" + month.toString() : month.toString();
    return year + "-" + monthStr + "-" + numDays;
  }

  _getStartDateByMonthId(month: number): string {
    const year = this._getCurrentYear();
    const monthStr = (month < 10) ? "0" + month.toString() : month.toString();
    return year + "-" + monthStr + "-01";
  }

  _getEndDateByMonthIdAndYear(year: number, month: number): string {
    year = year ? year : this._getCurrentYear();
    const numDays = this._getNumberOfDaysInMonth(year, month);
    const monthStr = (month < 10) ? "0" + month.toString() : month.toString();
    return year + "-" + monthStr + "-" + numDays;
  }

  _getStartDateByMonthIdAndYear(year: number, month: number): string {
    year = year ? year : this._getCurrentYear();
    const monthStr = (month < 10) ? "0" + month.toString() : month.toString();
    return year + "-" + monthStr + "-01";
  }

  _getTodaysDate() {
    const todayDate = new Date();
    return this.getUTCTimeStampFromDate(todayDate, false);
  }

  _getFirstDayOfCurrentMonthDate() {
    const currMonth = this._getCurrentMonth();
    return this._getStartDateByMonthId(currMonth);
  }

  getPaddedDigit(digit: number): string {
    return (digit < 10) ? '0' + digit : digit.toString();
  }

  getUTCTimeStampFromDate(date: Date, includeMilliSeconds: boolean): string {
    let timeStampStr = "";
    if (date) {
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1;
      const day = date.getUTCDate();
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const seconds = date.getUTCSeconds();

      timeStampStr = year + '-' + this.getPaddedDigit(month) + '-' + this.getPaddedDigit(day) + ' '
        + this.getPaddedDigit(hours) + ':' + this.getPaddedDigit(minutes) + ':' + this.getPaddedDigit(seconds);

      if (includeMilliSeconds) {
        const ms = date.getUTCMilliseconds();
        timeStampStr += '.' + this.getPaddedDigit(ms);
      }
    }
    return timeStampStr;
  }

}


