import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { IAppConfig } from "../../config/app-config.interface";
import { APP_CONFIG } from "../../config/app-config.constants";
import * as AMS from '@core/models/account.management.models';

@Injectable({
  providedIn: 'root',
})
export class AgencyManagementService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) protected config: IAppConfig,
  ) { }


  getAgencyOdometerData(filterPendingOdometerApprovals: AMS.IFilterPendingOdometerApprovals) {
    return this.http
      .post<AMS.IAgencyPendingOdometerApprovalsResponse>(environment.apiUrl + this.config.api_account_management_agency_odometer_data, filterPendingOdometerApprovals)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getOdometerRecordDetails(odometerId: number) {
    return this.http
      .post<AMS.IAgencyOdometerRecordDetailsResponse>(environment.apiUrl + this.config.api_odometer_record_detail, { "odometer_id": odometerId })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateOdometerRecord(updateObj: AMS.IUpdateOdometerRecord) {
    return this.http
      .put<AMS.IUpdateOdometerRecordResponse>(environment.apiUrl + this.config.api_update_odometer_record, updateObj)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getManualReviewVINRecords(filterManualVINApprovals: AMS.IFilterManualVINApprovals) {
    return this.http
      .post<AMS.IAgencyManualVINReviewResponse>(environment.apiUrl + this.config.api_pending_vin_approvals_list, filterManualVINApprovals)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getVINImageRecordDetails(vinId: number) {
    return this.http
      .post<AMS.IAgencyVINImageRecordDetailsResponse>(environment.apiUrl + this.config.api_vin_record_detail, { "vin_id": vinId })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateVINImageRecord(updateObj: AMS.IUpdateVINRecord) {
    return this.http
      .put<AMS.IUpdateVINRecordResponse>(environment.apiUrl + this.config.api_update_vin_record, updateObj)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  uploadConsumerAccountsData(updateObj: any) {
    return this.http
      .post<AMS.IAMSUploadConsumersDataResponse>(environment.apiUrl + this.config.api_upload_customers_migration_data, updateObj)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  uploadConsumersVINSummaryData(updateObj: any) {
    return this.http
      .post<AMS.IAMSUploadConsumersDataResponse>(environment.apiUrl + this.config.api_upload_customers_ruc_messages_data, updateObj)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

}


