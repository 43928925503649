import { RouteInfo } from '../sidebar.metadata';

export const CONSUMER_ROUTES: RouteInfo[] = [
  {
    path: '/myaccount/home',
    title: 'Home',
    iconType: 'material-icons-two-tone',
    icon: 'people',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    backendPermissionVar: '',
  },
  {
    path: '/myaccount/vehicles',
    title: 'My Vehicles',
    iconType: 'material-icons-two-tone',
    icon: 'directions_car',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    backendPermissionVar: '',
  },
  {
    path: '/myaccount/contacts',
    title: 'My Contacts',
    iconType: 'material-icons-two-tone',
    icon: 'contacts',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    backendPermissionVar: 'c_consumer_portal_enable_my_contacts',
  },
  {
    path: '/myaccount/devices',
    title: 'My Devices',
    iconType: 'material-icons-two-tone',
    icon: 'devices',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    backendPermissionVar: 'c_consumer_portal_enable_my_devices',
  },
  {
    path: '/myaccount/wallet',
    title: 'My Wallet',
    iconType: 'material-icons-two-tone',
    icon: 'account_balance_wallet',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    backendPermissionVar: 'c_consumer_portal_enable_my_wallet',
  },
  {
    path: '/myaccount/statements',
    title: 'My Statements',
    iconType: 'material-icons-two-tone',
    icon: 'file_download',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    backendPermissionVar: 'c_consumer_portal_enable_my_statements',
  },
  {
    path: '/myaccount/ruc-transactions',
    title: 'My RUC Transactions',
    iconType: 'material-icons-two-tone',
    icon: 'paid',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    backendPermissionVar: 'c_consumer_portal_enable_my_ruc_transactions',
  },
  {
    path: '/myaccount/etoll-transactions',
    title: 'My E-Tolling Transactions',
    iconType: 'material-icons-two-tone',
    icon: 'paid',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    backendPermissionVar: 'c_consumer_portal_enable_my_etoll_transactions',
  },
  {
    path: '/myaccount/payment-methods',
    title: 'My Payment Methods',
    iconType: 'material-icons-two-tone',
    icon: 'payments',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    backendPermissionVar: 'c_consumer_portal_enable_my_payment_methods',
  },
  {
    path: '/myaccount/login-history',
    title: 'My Login History',
    iconType: 'material-icons-two-tone',
    icon: 'history',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    backendPermissionVar: 'c_consumer_portal_enable_my_login_history',
  },
  {
    path: '/myaccount/cases',
    title: 'My Cases',
    iconType: 'material-icons-two-tone',
    icon: 'cases',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: 'badge bg-orange sidebar-badge float-end',
    role: ['admin', 'agency'],
    submenu: [],
    envPermissionVar: 'showCustomerSupport',
    backendPermissionVar: 'c_consumer_portal_enable_my_cases',
  },

  // {
  //   path: '/myaccount/billing',
  //   title: 'Billing',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'attach_money',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['admin', 'agency'],
  //   submenu: [],
  // },
  // {
  //   path: '/myaccount/faqs',
  //   title: 'FAQs',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'eco',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['admin', 'agency'],
  //   submenu: [],
  // },
  {
    path: '/myaccount/contact-us',
    title: 'Customer Support',
    iconType: 'material-icons-two-tone',
    icon: 'eco',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    envPermissionVar: 'showCustomerSupport',
    backendPermissionVar: 'c_consumer_portal_enable_customer_support',
  },
  {
    path: '/myaccount/profile',
    title: 'Manage Profile',
    iconType: 'material-icons-two-tone',
    icon: 'settings',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['admin', 'agency'],
    submenu: [],
    backendPermissionVar: '',
  },
];

