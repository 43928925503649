import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConsumerPortalConfigurationService {

  public getEnvironmentConfig() {
    // console.log(environment);
    return environment;
  }
}
