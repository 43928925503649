<div [dir]="direction">
  <app-consumer-header></app-consumer-header>
  <router-outlet></router-outlet>

  <footer class="footer">
    <div class="container-lg">
      <div class="row">
        <div class="col-xs-12 col-md-3">

          <ng-container *ngIf="envConfig.portalDataInfo.agencyId === 11">
            <p class="footer-title">GeoToll RUC Programs</p>
            <mat-divider class="divider"></mat-divider>
            <ul>
              <li><a href="/#/home">What is the GeoToll RUC Program?</a></li>
              <li><a href="/#/home/faqs">GeoToll Program FAQ</a></li>
            </ul> 
          </ng-container>

          <ng-container *ngIf="envConfig.portalDataInfo.agencyId === 115">
            <p class="footer-title">Orego Programs</p>
            <mat-divider class="divider"></mat-divider>
            <ul>
              <li><a href="/#/home">What is the OReGO Program?</a></li>
              <li><a href="/#/home/faqs">OReGO Program FAQ</a></li>
            </ul>
          </ng-container>

          <ng-container *ngIf="envConfig.portalDataInfo.agencyId === 140">
            <p class="footer-title">TECT New Jersey Programs</p>
            <mat-divider class="divider"></mat-divider>
            <ul>
              <li><a href="/#/home">What is the TETC NJ Program?</a></li>
              <li><a href="/#/home/faqs">TECT NJ Program FAQ</a></li>
            </ul>
          </ng-container>

          <ng-container *ngIf="envConfig.portalDataInfo.agencyId === 130">
            <p class="footer-title">TECT Maryland Programs</p>
            <mat-divider class="divider"></mat-divider>
            <ul>
              <li><a href="/#/home">What is the TETC MD Program?</a></li>
              <li><a href="/#/home/faqs">TETC MD Program FAQ</a></li>
            </ul>
          </ng-container>
        </div>
        
        <div class="col-xs-12 col-md-3">
          <p class="footer-title">Consumer Agreements</p>
          <mat-divider class="divider"></mat-divider>
          <ul>
            <li><a routerLink="/home/terms">Terms and Conditions</a></li>
            <li><a routerLink="/home/privacy-policy">Privacy Policy</a></li>
            <li><a target="_blank" href="https://secure.geotoll.com/guests/terms">Volunteer Agreement</a></li>
          </ul>
        </div>
        <div class="col-xs-12 col-md-3 col-xs-">
        </div>
        <div class="col-xs-12 col-md-3 footer-info">
          <div class="logo">
            <img src="assets/images/logos/{{envConfig.portalDataInfo.footerLogo}}" width="auto" height="auto">
          </div>
          &copy; {{copyrightYear}} GeoToll ({{envConfig.version}})
        </div>
      </div>
    </div>
  </footer>

</div>