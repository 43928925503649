import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeoTollConsumer } from '../models/user';
import { environment } from 'environments/environment';
import { IAppConfig } from "@config/app-config.interface";
import { APP_CONFIG } from "@config/app-config.constants";
import { ChangePasswordRequest, UpdateProfileRequest, ConsumerLoginRequest } from '@core/models/user';
@Injectable({
  providedIn: 'root',
})
export class ConsumerAuthService {
  private geotollConsumerSubject: BehaviorSubject<GeoTollConsumer>;
  public geotollConsumer: Observable<GeoTollConsumer>;
  private refreshTokenTimeout = null;
  private refreshTokenGap = 300000; // show modal 5min before token expiry

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) protected config: IAppConfig
  ) {
    this.geotollConsumerSubject = new BehaviorSubject<GeoTollConsumer>(
      JSON.parse(localStorage.getItem('geotoll.consumer') || '{}')
    );

    this.geotollConsumer = this.geotollConsumerSubject.asObservable();
  }

  public get geotollConsumerValue(): GeoTollConsumer {
    return this.geotollConsumerSubject.value;
  }

  verifyInviteCode(email: string, invite_code: number) {
    const url = environment.apiUrl + this.config.api_verify_register_invite;

    return this.http
      .post<any>(url, {
        email: email,
        invite_code: invite_code
      })
      .pipe(
        map((response) => {
          response.data.user.token = response.data.token;
          localStorage.setItem('geotoll.consumer', JSON.stringify(response.data.user));
          this.geotollConsumerSubject.next(response.data.user);
          return response.data.user;
        })
      );
  }

  consumerForgotPassword(email: string) {
    const url = environment.apiUrl + this.config.api_consumer_forgot_password + "/" + email;

    return this.http
      .get<any>(url)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  setConsumerPassword(requestObj: ChangePasswordRequest) {
    if (requestObj.old_password) return this.changeConsumerPassword(requestObj)
    return this.http
      .post<any>(environment.apiUrl + this.config.api_consumer_set_password, requestObj)
      .pipe(
        map((response) => {
          return response.data.user;
        })
      );
  }

  changeConsumerPassword(requestObj: ChangePasswordRequest) {
    return this.http
      .put<any>(environment.apiUrl + this.config.api_consumer_change_password, requestObj)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateConsumerCommunicationPreference(requestObj: UpdateProfileRequest) {
    return this.http
      .put<any>(environment.apiUrl + this.config.api_consumer_update_profile, requestObj)
      .pipe(
        map((response) => {
          const a = localStorage.getItem('geotoll.consumer');
          const userObj = JSON.parse(a || '{}');
          userObj.communication_pref = requestObj.communication_pref;
          // console.log(userObj);
          localStorage.setItem('geotoll.consumer', JSON.stringify(userObj));
          this.geotollConsumerSubject.next(userObj);
          return response;
        })
      );
  }

  login(consumerLoginRequest: ConsumerLoginRequest) {
    const url = environment.apiUrl + this.config.api_consumer_login;
    consumerLoginRequest.agency_id = environment.portalDataInfo.agencyId
    return this.http
      .post<any>(url, consumerLoginRequest)
      .pipe(
        map((response) => {
          response.data.user.token = response.data.token;
          localStorage.setItem('geotoll.consumer', JSON.stringify(response.data.user));
          this.geotollConsumerSubject.next(response.data.user);
          return response.data.user;
        })
      );
  }

  logout() {
    localStorage.removeItem('geotoll.consumer');
    this.geotollConsumerSubject.next(this.geotollConsumerValue);
    return of({ success: false });
  }




}
