import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../service/auth.service';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class AMSPortalGuard {
  isConsumerPortal = environment.consumerPortal;

  constructor(private authService: AuthService, private router: Router) { }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.isConsumerPortal) {
      return true;
    }
    this.authService.logout();
    this.router.navigate([environment.portalDataInfo.logoUrl]);
    return false;
  }
}
