import { Component } from '@angular/core';
import { BaseLayoutComponent } from '../base-layout.component';

@Component({
  selector: 'app-configuration-management-layout',
  templateUrl: './configuration-management-layout.component.html',
  styleUrls: [],
})

export class ConfigurationManagementLayoutComponent extends BaseLayoutComponent {

}
